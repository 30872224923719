import React from "react"
import BlockContent from "./block-content"
import Container from "./container"

import styles from "./blog-post.module.css"

function BlogPost({ _rawBody, title }) {
  return (
    <article className={styles.root}>
      <Container>
        <div className={styles.container}>
          {_rawBody && <BlockContent blocks={_rawBody} />}
        </div>
      </Container>
    </article>
  )
}

export default BlogPost
