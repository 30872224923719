import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import Container from "../components/container"
import GraphQLErrorList from "../components/graphql-error-list"
import BlogPost from "../components/blog-post"
import SEO from "../components/seo"
import Layout from "../containers/layout"

export const query = graphql`
  query BlogTemplateQuery($id: String!) {
    blog: sanityBlog(id: { eq: $id }) {
      id
      title
      excerpt
      publishedAt
      _rawBody
      slug {
        current
      }
    }
  }
`

const BlogTemplate = ({ data, errors }) => {
  const blog = data && data.blog

  return (
    <Layout>
      <Header header={blog.title} />

      {errors && <SEO title="GraphQL Error" />}
      {blog && <SEO title={blog.title || "Untitled"} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {blog && <BlogPost {...blog} />}
      <Footer />
    </Layout>
  )
}

export default BlogTemplate
