import React from 'react'
import LazyLoad from 'react-lazyload';

import styles from './video.module.css'

const Test = () => {
  console.log("render");
  return <div />
};

function Video (props) {
  return (
    <div>

      <div className={styles.embedContainer}>
      <LazyLoad height={200}>
        <iframe title="Video" src={props.url} frameborder='0' allowfullscreen></iframe>
        <Test />
      </LazyLoad>
      </div>

    </div>
  )
}

export default Video
