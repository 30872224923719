import React from 'react'
import LazyLoad from 'react-lazyload';

import styles from './povSplit.module.css'

function Video (props) {
  return (
    <div className={styles.grid}>

      <LazyLoad height={200}>

      <div>
        <div className={styles.name}>{props.redName}</div>
        <div className={styles.embedContainer}>
          <iframe title="Red Team" src={props.redUrl} frameborder='0' allowfullscreen></iframe>
        </div>
      </div>

      <div>
        <div className={styles.name}>{props.bluName}</div>
        <div className={styles.embedContainer}>
          <iframe title="Blu Team" src={props.bluUrl} frameborder='0' allowfullscreen></iframe>
        </div>
      </div>

      </LazyLoad>

    </div>
  )
}

export default Video
